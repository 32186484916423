<template>
  <span class="video-title-mask" :class="{ mpo: isMpo, fpo: isFpo }">
    <span
      v-for="(part, ix) in parts"
      :key="ix"
      class="title-part"
      :class="getClasses(part)">{{ part.value }}</span>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import { tryGetYear, matchesAny, matchCount } from './videoHelper'

export default {
  name: 'CensoredName',
  props: {
    name: String,
    censored: Boolean
  },
  computed: {
    ...mapGetters('ytlist', [
      'roundKeywords',
      'eventKeywords',
      'mpoKeywords',
      'fpoKeywords'
    ]),
    parts () {
      return this.getParts()
    },
    mpoCount () {
      return this.countKeywords(this.name, this.mpoKeywords)
    },
    fpoCount () {
      return this.countKeywords(this.name, this.fpoKeywords)
    },
    isMpo () {
      if (this.mpoCount >= 2 && this.fpoCount === 0) {
        return true
      }
      return false
    },
    isFpo () {
      if (this.fpoCount >= 2 && this.mpoCount === 0) {
        return true
      }
      return false
    }
  },
  methods: {
    getParts () {
      if (!this.censored) {
        return [{ value: this.name, censored: false }]
      }

      let parts = [{ value: this.name, censored: true }]
      parts = this.uncensorPart(parts, this.tryGetYear)
      parts = this.uncensorPart(parts, this.tryGetRound)
      parts = this.uncensorPart(parts, this.tryGetEvent)

      return parts
    },
    uncensorPart (parts, fn) {
      const newParts = []
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i]
        if (part.censored) {
          const foundValue = fn(part.value)
          if (foundValue != null) {
            const fullName = part.value
            const pos = fullName.indexOf(foundValue)
            if (pos > 0) {
              // Push first censored part
              const val = part.value.substr(0, pos)
              newParts.push({ value: val, censored: true })
            }

            // push what we're looking fore (uncensored)
            newParts.push({ value: foundValue, censored: false })

            // Push reset (censored)
            const restVal = part.value.substr(pos + foundValue.length)
            newParts.push({ value: restVal, censored: true })
          } else {
            newParts.push(part)
          }
        } else {
          newParts.push(part)
        }
      }

      return newParts
    },
    tryGetYear (value) {
      const yearNum = tryGetYear(value)
      if (yearNum != null) {
        return yearNum.toString()
      }
      return null
    },
    tryGetRound (value) {
      return matchesAny(this.roundKeywords, value)
    },
    tryGetEvent (value) {
      return matchesAny(this.eventKeywords, value)
    },
    countKeywords (value, keywords) {
      return matchCount(keywords, value)
    },
    partIsMpo (part) {
      const mpoCount = this.countKeywords(part.value, this.mpoKeywords)
      const fpoCount = this.countKeywords(part.value, this.fpoKeywords)
      return mpoCount >= 2 && fpoCount <= 1
    },
    partIsFpo (part) {
      const mpoCount = this.countKeywords(part.value, this.mpoKeywords)
      const fpoCount = this.countKeywords(part.value, this.fpoKeywords)
      return fpoCount >= 2 && mpoCount <= 1
    },
    getClasses (part) {
      return {
        mpo: this.partIsMpo(part),
        fpo: this.partIsFpo(part),
        censored: part.censored
      }
    }
  }
}
</script>
